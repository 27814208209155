import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

  const routes: Routes = [
    {
      path: "",
      loadChildren: () =>
        import("./modules/sign-up/sign-up.module").then((m) => m.SignUpModule),
    },
  ];
  
  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
  })
  export class AppRoutingModule {}