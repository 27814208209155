<div class="wrapper w-100 d-block">
    <header class="page_hdr d-flex w-100 align-center">
        <div class="hdr_inner d-flex w-100 align-center justify-between">
            <div class="hdr_left">
                <div class="logo_div" (click)="redirectToHome()">
                    <img src="../../../../assets/images/logo.svg" class="hdr_logo" alt="logo">
                </div>
            <!-- <div class="side_nav_div">
                <img src="../../../../assets/images/menu_icon.svg">
                <div class="side_nav_inner" [matMenuTriggerFor]="menu">
                    <mat-menu #menu="matMenu">
                       <div class="menu_close_div"> <button mat-button mat-dialog-close><img src="../../../../assets/images/job_close_icon.svg"></button></div>
                        <button mat-menu-item>Job Seekers</button>
                        <button mat-menu-item>For Employers</button>
                        <button mat-menu-item>About Us</button>
                        <button mat-menu-item>Contact Us</button>
                        <div class="hdr_right mobile">
                            <button mat-raised-button (click)="redirectToReferAFriend()" class="btn refer_btn">Refer A Friend</button>
                        </div>
                      </mat-menu>
                
                <div class="hdr_right mobile">
                    <button mat-raised-button (click)="redirectToReferAFriend()" class="btn refer_btn">Refer A Friend</button>
                     
                </div>
            </div>
             </div> -->
            </div>
            <div class="hdr_right big">
                <button mat-raised-button (click)="redirectToReferAFriend()" class="btn refer_btn">Refer A Friend</button>
            </div>
        </div>
    </header>
    <nav class="page_nav d-flex w-100 align-center">
        <div class="nav_inner d-flex w-100 align-center">
            <ul>
                <li><a href="#">Job Seekers</a></li>
                <li><a href="#">For Employers</a></li>
                <li><a href="#">About Us</a></li>
                <li><a href="#">Contact Us</a></li>
            </ul>
        </div>
    </nav>
    <router-outlet></router-outlet>
    <footer class="page_footer d-flex w-100">
        <div class="footer_inner d-flex w-100"> 
            <div class="footer_top d-flex w-100">
            <div class="footer_single">
              <p class="w-100 d-flex">  <img src="../../../../assets/images/footer_logo.svg"> </p>
              <div class="footer_single_sec w-100 d-flex contact">  <img src="../../../../assets/images/footer_email.svg"> <span>info&#64;softpath.net</span></div>
              <div class="footer_single_sec w-100 d-flex contact">  <img src="../../../../assets/images/footer_phone.svg"> <span>404-315-1555</span></div>
              <div class="footer_single_sec w-100 d-flex contact">  <img src="../../../../assets/images/footer_location.svg"><p>3985 Steve Reynolds Blvd, <br/>Bldg C, Norcross, GA 30093</p></div>
            </div>

            <div class="footer_single">
                <p class="footer_single_hdg">About Us</p>
                
                <div class="footer_single_sec w-100 d-flex">  <a href="https://www.softpath.net/healthcare.html" target="_blank" class="footer_link">Softpath Healthcare Overview</a></div>
                <div class="footer_single_sec w-100 d-flex">  <a href="https://www.softpath.net/about.html#leadership-team" target="_blank" class="footer_link">Leadership</a></div>
                <div class="footer_single_sec w-100 d-flex">  <a href="https://www.softpath.net/blog.html#our-blogs" target="_blank" class="footer_link">Blogs</a></div>
              </div>

              <div class="footer_single">
                <p class="footer_single_hdg">Get in Touch</p>
                <div class="footer_single_sec w-100 d-flex">  
                    <p class="footer_single_sec_hdg w-100">Contact Us</p>
                    <p class="footer_social_links d-flex w-100">
                    <a href="https://instagram.com/softpath_system?utm_medium=copy_link"><img src="../../../../assets/images/instagram.svg"> </a>
                    <!-- <a href="#"><img src="../../../../assets/images/youtube.svg"></a>
                    <a href="#"><img src="../../../../assets/images/twitter.svg"></a> -->
                    <a href="https://www.facebook.com/Softpath-System-LLC-267900871732518"><img src="../../../../assets/images/facebook.svg"></a>
                    </p>
                
                </div>
                
              </div>

              <div class="footer_single">
                <div class="footer_single_sec w-100 d-flex certificate">  <img src="../../../../assets/images/certified_1.svg"><img src="../../../../assets/images/certified_2.svg" class="certified_2"> </div>
                
              </div>
            </div>
            <div class="footer_bottom d-flex w-100">
                <ul>
                    <li><a [routerLink]="['/rights-protection']">Rights & Protections</a></li>
                    <li><a [routerLink]="['/cookie-policy']">Cookie Policy</a></li>
                    <li><a [routerLink]="['/privacy-policy']">Your Privacy Rights</a></li>
                    <li><a routerLink="/all-jobs">All Jobs</a></li>
                    
                </ul>
                <p class="copy_right">&copy; Softpath Medical 2024 all rights reserved</p>
            </div>

        </div>
    </footer>
</div>





